import { SharedConfig } from '../../../shared/shared.config';
import { environment } from './environment';

export const ApplicationConfig = {
  ...SharedConfig,

  WEB_ENDPOINT: environment.production
    ? 'https://timekit.by/'
    : 'http://localhost:4200/',

  API_ENDPOINT: environment.production
    ? 'https://server.timekit.by/api/'
    : 'https://server.timekit.by/api/'
    // : 'http://localhost:8080/api/'
};
